<template>
  <div class="page flex-col">
    <header>
      <van-nav-bar
              title="会员介绍"
              left-text=""
              left-arrow
              @click-left="onClickLeft"
      />
    </header>
    <section>
    <div class="box_1 flex-row">
      <div class="group_1 flex-col">

      </div>
      <div class="group_2 flex-col">
        <span class="text_5">平台介绍</span>
        <span class="text_6" style="text-indent: 2em;letter-spacing: 0.1em;">
          渡爽平台是湖北渡爽科技有限公司旗下品牌，
          渡爽平台专注于餐饮运营、数据赋能、内容生产、产业孵化等项目板块。
          <br>渡爽平台包含：
          <br>渡爽养生菜品牌运营系统、渡爽餐饮孵化体系、渡爽生活馆运营系统、渡爽供应链系统、
          渡爽药膳研学院、渡爽大数据系统、渡爽养生商城等业务板块。
        </span>
     <!--
        <div class="group_3 flex-col">
          <img style="height: 10.827rem;" src="../enterMall/assets/img/store_img.png"/>
        </div>
        -->
        <span class="text_7">会员等级</span>
        <span class="text_8" style="letter-spacing: 0.1em;">
          <span style="font-weight: bold;">一、银卡会员</span>
          <br>1、凡是在渡爽养生菜店铺消费任意菜品的客户，皆为渡爽养生菜银卡会员。
          <br>2、享有所有分享消费客户之美食积分20%激励。
          <br>3、享有所有分享延伸客户消费之美食积分30%激励。
          <br>4、银卡会员美食积分可在所有渡爽养生菜店铺核销菜品或产品。
          <br><span style="font-weight: bold;">二、金卡会员</span>
          <br>1、消费渡爽养生菜指定产品800元，成为渡爽养生菜金卡会员。
          <br>2、享有渡爽养生菜所有店铺9.5折优惠（非活动价，活动期间会员价格同活动价）。
          <br>3、享有渡爽整体平台不定期活动激励。
          <br>4、享有所有分享消费客户之美食积分（Food points，简写FP）30%激励。
          <br>5、享有所有分享延伸客户消费之美食积分（FP）30%激励。
          <br>6、金卡会员美食积分可在所有渡爽养生菜店铺核销菜品或产品。
        </span>
        <div class="text-wrapper_2 flex-col" @click="addOrderInfo">
          <span class="text_9">立即购买</span></div>
      </div>
    </div>
    </section>
  </div>
</template>
<script>
  import {addMallOrderInfo} from "@/api/order";
export default {
  data() {
    return {
      orderParams: {
        shopId: 0,
        orderPrice:800,//购买会员定价800
        memberAccount:'',
        orderNum:0,
        addressId:0,
        type:3,//1菜品2商品3购买会员订单
        sysOrderDetails:[]
      }
    };
  },
  methods: {
    addOrderInfo() {
      const user = localStorage.getItem('userInfoDs')
       if(user){
         this.orderParams.memberAccount = JSON.parse(user).account
         this.orderParams.memberId = JSON.parse(user).memberId
         addMallOrderInfo(this.orderParams).then(response => {
           if (response.code == 200) {
             this.$router.push('/userOrder')
           }else{
             this.$model({
               show: true,
               title: "提示",
               content: response.msg,
               confirmButton: false,
               cancelButton: true
             });
           }
         });
       }else{
         this.$router.push('/login')
       }
    },
    onClickLeft(){
      this.$router.go(-1)
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />